<template>
  <b-card-body :class="{'loading': loading}">
    <auction-report-with-manager-table
      :data="data"
      :total="total"
      :period="filter.period"
    />
    <b-row>
      <b-col
        lg="12"
      >
        <played-placed-auctions-chart
          :data="data"
          :period="filter.period"
        />
      </b-col>
      <b-col
        lg="12"
      >
        <plan-auctions-chart
          :data="data"
          :period="filter.period"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import AuctionReportWithManagerTable
  from './AuctionReportWithManagerTable';
import {customersManagerAuctionStatistics} from '@/services/api';
import PlayedPlacedAuctionsChart from './PlayedPlacedAuctionsChart';
import PlanAuctionsChart from './PlanAuctionsChart';
export default {
  name: 'AuctionReportWithManager',
  components: {
    PlanAuctionsChart,
    PlayedPlacedAuctionsChart,
    AuctionReportWithManagerTable,
  },
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.customersManagerAuctionStatisticsChartsData(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.customersManagerAuctionStatisticsChartsData(this.filter);
  },
  methods: {
    async customersManagerAuctionStatisticsChartsData(data) {
      this.loading = true;
      const response = await customersManagerAuctionStatistics(this.$store.state.user.ownerId, Object.assign(data));
      if (response && response.status === 200) {
        this.total= response.data.total;
        this.data = response.data.page;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped  lang="scss">
</style>
